<!-- 支付成功页面 -->
<template>
  <div>
    <HeadGoBackVue iCustomizdde="true">
      <template #default>
        <span> 支払い失敗しました </span>
      </template>
    </HeadGoBackVue>
    <div class="paymentSuccessBox">
      <img
        class="successIcon"
        :src="require('@/assets/icon/shibai.png')"
        alt=""
      />
      <div class="successFont">
        <p>支払い失敗しました</p>
        <p>
          入金画面生成エラー、お手数をおかけしますが、画面を閉じて、再度操作をお願いいたします。
        </p>
      </div>

      <button
        class="operationButton red"
        @click="$fun.routerToPage('/user/purse')"
      >
        {{ $fanyi("回到钱包") }}
      </button>
      <!-- <button class="operationButton red" @click="$fun.routerToPage('/user/index')">
          {{ $fanyi("回到个人中心") }}
        </button>
        <button class="operationButton" @click="$fun.routerToPage('/')">
          {{ $fanyi("回到首页") }}
                                                                            </button> -->
    </div>
  </div>
</template>
<script setup>
import HeadGoBackVue from "../../components/headGoBack/index.vue";
import { getCurrentInstance, ref } from "vue";
const { proxy } = getCurrentInstance();
//------------------------ data -----------------------------------------
//------------------------ pageLoad -------------------------------------
//------------------------ methods --------------------------------------
defineExpose({});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.paymentSuccessBox {
  width: 690px;
  background: #ffffff;
  border-radius: 6px;
  padding: 60px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto 0;

  .successIcon {
    width: 80px;
    height: 80px;
    margin-bottom: 41px;
  }

  .successFont {
    font-size: 32px;
    color: #000000;
    line-height: 50px;
    text-align: center;
    p {
      font-size: 27px;
      line-height: 35px;
      text-align: center;
      &:first-child {
        font-size: 32px;
        margin-bottom: 15px;
        font-weight: bold;
        text-align: center;
      }
    }
  }

  .transactionNumber {
    margin-top: 60px;
    padding: 42px 0px;
    width: 100%;

    p {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 42px;

      &:last-child {
        margin-bottom: 0;
      }

      .title {
        font-size: 28px;
        color: #000000;

        text-align: left;
      }

      .con {
        font-size: 28px;
        color: #000000;
      }
    }
  }

  .operationButton {
    padding: 0 50px;
    height: 70px;
    background: #ffffff;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    font-size: 28px;
    color: #000000;
  }

  .operationButton.red {
    margin-top: 60px;
    background: #b4272b;
    color: #ffffff;
  }
}
</style>
